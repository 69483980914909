import templateEngine from 'oneapp/src/utils/template';

(function(app, $) {
	var emojisRegex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

	/**
	 * @module app.util
	 */
	app.util = {
		convertor: {
			/**
			 * Converts celsius to fahrenheit
			 * @param {number} value - Value in celsius
			 * @param {boolean} [isRound=true] - Is round value to integer
			 * @returns {string}
			 */
			celsiusToFahrenheit: function(value, isRound = true) {
				const result = value * 1.8 + 32;

				return isRound ? Math.round(result) : result;
			}
		},

		/**
		 * @function
		 * @description Return a copy of the object only containing the whitelisted properties.
		 * @param {Object} obj
		 * @param {String[]} properties
		 * @return {Object}
		 */
		pick: function pick(obj, properties) {
			var copy = {};
			$.each(properties, function(i, key) {
				if (key in obj) {
					copy[key] = obj[key];
				}
			});
			return copy;
		},
		/**
		 * @function
		 * @description trims a prefix from a given string, this can be used to trim
		 * a certain prefix from DOM element IDs for further processing on the ID
		 */
		trimPrefix: function(str, prefix) {
			return str.substring(prefix.length);
		},
		
		/**
		 * @function form2Object
		 * @description converts form fields to object
		 *
		 */
		form2Object: function($form, namesOnly, includeDisabled) {
			var object = {};
			var formData = null;
			
			if (includeDisabled) {
				formData = $form.serializeArrayAll();
			}
			else {
				formData = $form.serializeArray();
			}

			$.each(formData, function() {
				var name = this.name;
				if (name.indexOf('dwfrm_') !== -1) {
					name = name.substr(6);
				}
				if (namesOnly) {
					name = name.split('_').pop();
				}
				if (object[name] !== undefined) {
					if (!object[name].push) {
						object[name] = [object[name]];
					}
					object[name].push(this.value || '');
				} else {
					object[name] = this.value || '';
				}
			});
			return object;
		},

		/**
		 * @function
		 * @description
		 */
		setDialogify: function(e) {
			e.preventDefault();
			var actionSource = $(this),
				dlgAction = $(actionSource).data('dlg-action') || {}, // url, target, isForm
				dlgOptions = $.extend({}, app.dialog.settings, $(actionSource).data('dlg-options') || {});

			dlgOptions.title = dlgOptions.title || $(actionSource).attr('title') || '';

			var url =
				dlgAction.url || // url from data
				(dlgAction.isForm
					? $(actionSource)
							.closest('form')
							.attr('action')
					: null) || // or url from form action if isForm=true
				$(actionSource).attr('href'); // or url from href

			if (!url) {
				return;
			}

			var form = jQuery(this).parents('form');
			var method = form.attr('method') || 'POST';

			// if this is a content link, update url from Page-Show to Page-Include
			if ($(this).hasClass('attributecontentlink')) {
				var uri = app.util.getUri(url);
				url = app.urls.pageInclude + uri.query;
			}
			if (method && method.toUpperCase() == 'POST') {
				var postData = form.serialize() + '&' + jQuery(this).attr('name') + '=submit';
			} else {
				if (url.indexOf('?') == -1) {
					url += '?';
				} else {
					url += '&';
				}
				url += form.serialize();
				url = app.util.appendParamToURL(url, jQuery(this).attr('name'), 'submit');
			}

			var dlg = app.dialog.create({ target: dlgAction.target, options: dlgOptions });

			app.ajax.load({
				url:
					$(actionSource).attr('href') ||
					$(actionSource)
						.closest('form')
						.attr('action'),
				target: dlg,
				callback: function() {
					dlg.dialog('open'); // open after load to ensure dialog is centered
					app.validator.init(); // re-init validator
				},
				data: !$(actionSource).attr('href') ? postData : null
			});
		},
		/**
		 * @function
		 * @description Appends a character to the left side of a numeric string (normally ' ')
		 * @param {String} str the original string
		 * @param {String} padChar the character which will be appended to the original string
		 * @param {Number} len the length of the end string
		 */
		padLeft: function(str, padChar, len) {
			var digs = len || 10;
			var s = str.toString();
			var dif = digs - s.length;
			while (dif > 0) {
				s = padChar + s;
				dif--;
			}
			return s;
		},

		/**
		 * @function
		 * @description appends the parameter with the given name and value to the given url and returns the changed url
		 * @param {String} url the url to which the parameter will be added
		 * @param {String} name the name of the parameter
		 * @param {String} value the value of the parameter
		 */
		appendParamToURL: function(url, name, value) {
			url = app.util.removeParamFromURL(url, name);

			var c = '?';
			var parts = url.split(/(#.*$)/, 2);
			if (parts[0].indexOf(c) !== -1) {
				c = '&';
			}
			var query_pair = c + name + '=' + encodeURIComponent(value);
			return parts[0] + query_pair + (parts[1] || '');
		},

		/**
		 * @function
		 * @description appends the parameter with the given name and value and hash to the given url and returns the changed url
		 * @param {String} url the url to which the parameter will be added
		 * @param {String} name the name of the parameter
		 * @param {String} value the value of the parameter
		 * @param {String} hash the hash
		 */
		appendParamAndHashToURL: function(url, name, value, hash) {
			var c = '?';
			if (url.indexOf(c) !== -1) {
				c = '&';
			}

			var uri = app.util.getUri(url);

			var result = uri.urlWithQuery + c + name + '=' + encodeURIComponent(value) + hash;

			if (result.indexOf('http') < 0 && result.charAt(0) !== '/') {
				result = '/' + result;
			}

			return result;
		},

		/**
		 * @function
		 * @description appends the hash to the given url and returns the changed url
		 * @param {String} url the url to which the hash will be added
		 * @param {String} hash the hash
		 */
		appendHashToURL: function(url, hash) {
			var uri = app.util.getUri(url);

			var result = uri.urlWithQuery + hash;

			if (result.indexOf('http') < 0 && result.charAt(0) !== '/') {
				result = '/' + result;
			}

			return result;
		},

		appendHashParamToURL: function(url, name, value) {
			url = app.util.removeHashParamFromURL(url, name);

			var urlArr = url.split('#');
			var newUrl = urlArr[0] + '#';

			if (urlArr.length === 2 && urlArr[1].length > 0) {
				newUrl += urlArr[1] + '&';
			}

			newUrl += name + '=' + value;

			return newUrl;
		},

		removeHashParamFromURL: function(url, name) {
			var urlArr = url.split('#');
			var newUrl = urlArr[0];

			if (urlArr.length === 2 && urlArr[1].length > 0) {
				var params = urlArr[1].split('&');
				for (var i = 0, len = params.length; i < len; ++i) {
					if (params[i].split('=')[0] !== name) {
						newUrl += ((newUrl.indexOf('#') === -1) ? '#' : '&') + params[i];
					}
				}
			}

			return newUrl;
		},

		/**
		 * @function
		 * @description checking, object have visible coordinates or not
		 * @param {String}
		 * @param {String}
		 */
		elementInViewport: function(element, offsetToTop) {
			var rect = element.getBoundingClientRect(),
				offset = $(element).offset(),
				result,
				offsetToTop = offsetToTop || 0,
				shiftedTop = -offsetToTop,
				wHeight = window.innerHeight || document.documentElement.clientHeight,
				wWidth = window.innerWidth || document.documentElement.clientWidth;

			// Check if element is visible in DOM
			if (element.offsetParent === null) {
				return false;
			}

			result = rect.top >= shiftedTop && rect.left >= 0 && rect.bottom - offsetToTop <= wHeight && rect.right <= wWidth;

			if (rect.top !== offset.top && rect.right > wWidth) {
				result = offset.top >= shiftedTop && rect.left >= 0 && rect.bottom - offsetToTop <= wHeight;
			}
			return result;
		},

		/**
		 * @function
		 * @description appends the parameters to the given url and returns the changed url
		 * @param {String} url the url to which the parameters will be added
		 * @param {String} params a JSON string with the parameters
		 */
		appendParamsToUrl: function(url, params, notEncode) {
			var notEncode = typeof notEncode !== 'undefined' ? notEncode : false;

			var uri = app.util.getUri(url, notEncode),
				origin = '',
				includeHash = arguments.length < 3 ? false : arguments[2];

			if (url.indexOf('http') === 0) {
				origin = uri.protocol + '//' + uri.hostname;
			} else if (url.indexOf('//') === 0) {
				origin = '//' + uri.hostname;
			}

			origin += (uri.port && url.indexOf(origin + ':' + uri.port) === 0) ? (':' + uri.port) : '';

			var qsParams = $.extend(uri.queryParams, params);
			var path = uri.path.charAt(0) === '/' ? uri.path : '/' + uri.path;
			var result = origin + path + '?' + app.util.convertMapToQueryString(qsParams, notEncode);
			if (includeHash) {
				result += uri.hash;
			}

			return result;
		},

		/**
		 * @function
		 * @description appends the parameter from window.location.href if exist to the given url and returns the changed url
		 * @param {String} url the url to which the parameters will be added
		 * @param {String} param parameter to add ro url
		 * @return {String} changed url
		 */
		appendParamFromLocationHref: function(url, param) {
			var locationHrefQuery = this.getQueryStringParams(window.location.href);
			var urlQuery = this.getQueryStringParams(url);

			if (param && param in locationHrefQuery && !(param in urlQuery)) {
				url = this.appendParamToURL(url, param, locationHrefQuery[param]);
			}

			return url;
		},

		/**
		 * @function
		 * @description Converts provided map to query string
		 * @param {Object} map
		 *
		 * @return {String}
		 */

		convertMapToQueryString: function(map, notEncode) {
			var notEncode = typeof notEncode !== 'undefined' ? notEncode : false;

			if (!map) {
				return '';
			}
			var mapElements = [];
			for (var key in map) {
				if (notEncode === true) {
					mapElements.push(key + '=' + map[key]);
				} else {
					mapElements.push(encodeURIComponent(key) + '=' + encodeURIComponent(map[key]));
				}
			}
			return mapElements.join('&');
		},

		/**
		 * @function
		 * @description removes the parameter with the given name from the given url and returns the changed url
		 * @param {String} url the url from which the parameter will be removed
		 * @param {String} parameter name the name of the parameter
		 */
		removeParamFromURL: function(url, parameter) {
			var urlparts = url.split('?');

			if (urlparts.length >= 2) {
				var urlBase = urlparts.shift();
				var queryString = urlparts.join('?');

				var prefix = encodeURIComponent(parameter) + '=';
				var pars = queryString.split(/[&;]/g);
				var i = pars.length;
				while (0 < i--) {
					if (pars[i].lastIndexOf(prefix, 0) !== -1) {
						pars.splice(i, 1);
					}
				}
				url = urlBase + (pars.length > 0 ? '?' + pars.join('&') : '');
			}
			return url;
		},
		/**
		 * @function
		 * @description Searching parameters within "LIKE" method and removing this parameter. Please use this method safe
		 * @param {String} url the url from which the parameter will be removed
		 * @param {Array} names of parameters to be removed
		 */

		removeCountedParametersFromURL: function(url, parameters) {
			var newUrl = url;
			if (parameters.length > 0) {
				for (var i = 0; i <= parameters.length; i++) {
					for (var y = 1; y <= String(url).split(parameters[i]).length; y++) {
						newUrl = app.util.removeParamFromURL(newUrl, parameters[i] + y);
					}
				}
			}
			return newUrl;
		},

		/**
		 * @function
		 * @description Searching parameters within "LIKE" method and removing this parameter. Please use this method safe
		 * @param {String} url the url from which the parameter will be removed
		 * @param {Array} names of parameters to be removed
		 */

		removeParamsFromURL: function(url, parameters) {
			var newUrl = url;
			if (parameters.length > 0) {
				for (var i = 0; i <= parameters.length; i++) {
					newUrl = app.util.removeParamFromURL(newUrl, parameters[i]);
				}
			}
			return newUrl;
		},

		/**
		 * @function
		 * @description Compares two URLs by query parameters. Return true if parameter values are equal.
		 * @param {String} url1
		 * @param {String} url2
		 * @param {RegExp} regular expression for parameters that should be compared
		 */

		equalUrlParams: function(url1, url2, paramsReg) {
			if (paramsReg) {
				var url1Params = this.getQueryStringParams(url1),
					url2Params = this.getQueryStringParams(url2),
					paramObj = $.extend({}, url1Params, url2Params);
				for (var p in paramObj) {
					var url1Param = url1Params[p] && url1Params[p].split('#')[0],
						url2Param = url2Params[p] && url2Params[p].split('#')[0];
					if (p.match(paramsReg) && url1Param !== url2Param) {
						return false;
					}
				}
			} else {
				return this.getUri(url1).query === this.getUri(url2).query;
			}
			return true;
		},

		/**
		 * @function
		 * @description Returns the static url for a specific relative path
		 * @param {String} path the relative path
		 */
		staticUrl: function(path) {
			if (!path || $.trim(path).length === 0) {
				return app.urls.staticPath;
			}

			return app.urls.staticPath + (path.charAt(0) === '/' ? path.substr(1) : path);
		},
		/**
		 * @function
		 * @description Appends the parameter 'format=ajax' to a given path
		 * @param {String} path the relative path
		 */
		ajaxUrl: function(path) {
			return app.util.appendParamToURL(path, 'format', 'ajax');
		},

		/**
		 * @function
		 * @description
		 * @param {String} url
		 */
		toAbsoluteUrl: function(url) {
			if (!url) {
				return null;
			}
			if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
				url = '/' + url;
			}
			return url;
		},

		/**
		 * @function
		 * @check and set the absolute url path if the input path is relative
		 * @param {String} url
		 */
		getAbsoluteUrl: function(url) {
			if (url.charAt(0) === '/') {
				url = window.location.protocol + '//' + window.location.host + url;
			}
			return url;
		},

		/**
		 * @function
		 * @description Loads css dynamically from given urls
		 * @param {Array} urls Array of urls from which css will be dynamically loaded.
		 */
		loadDynamicCss: function(urls) {
			var i,
				len = urls.length;
			for (i = 0; i < len; i++) {
				app.util.loadedCssFiles.push(app.util.loadCssFile(urls[i]));
			}
		},

		/**
		 * @function
		 * @description Loads css file dynamically from given url
		 * @param {String} url The url from which css file will be dynamically loaded.
		 */
		loadCssFile: function(url) {
			return $('<link/>')
				.appendTo($('head'))
				.attr({
					type: 'text/css',
					rel: 'stylesheet'
				})
				.attr('href', url); // for i.e. <9, href must be added after link has been appended to head
		},
		// array to keep track of the dynamically loaded CSS files
		loadedCssFiles: [],

		/**
		 * @function
		 * @description Removes all css files which were dynamically loaded
		 */
		clearDynamicCss: function() {
			var i = app.util.loadedCssFiles.length;
			while (0 > i--) {
				$(app.util.loadedCssFiles[i]).remove();
			}
			app.util.loadedCssFiles = [];
		},
		/**
		 * @function
		 * @description Extracts all parameters from a given query string into an object
		 * @param {String} qs The query string from which the parameters will be extracted
		 */
		getQueryStringParams: function(qs, notEncode) {
			var notEncode = typeof notEncode !== 'undefined' ? notEncode : false;

			if (!qs || qs.length === 0) {
				return {};
			}

			var params = {},
				decodedQS;

			if (notEncode === true) {
				decodedQS = qs;
			} else {
				decodedQS = decodeURIComponent(qs);
			}
			// Use the String::replace method to iterate over each
			// name-value pair in the string.
			decodedQS.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function($0, $1, $2, $3) {
				params[$1] = $3;
			});
			return params;
		},
		/**
		 * @function
		 * @description Returns an URI-Object from a given element with the following properties:<br/>
		 * <p>protocol</p>
		 * <p>host</p>
		 * <p>hostname</p>
		 * <p>port</p>
		 * <p>path</p>
		 * <p>query</p>
		 * <p>queryParams</p>
		 * <p>hash</p>
		 * <p>url</p>
		 * <p>urlWithQuery</p>
		 * @param {Object} o The HTML-Element
		 */
		getUri: function(o, notEncode) {
			var notEncode = typeof notEncode !== 'undefined' ? notEncode : false;

			var a;
			if (o && o.tagName && $(o).attr('href')) {
				a = o;
			} else if (typeof o === 'string') {
				a = document.createElement('a');
				a.href = o;
			} else {
				return null;
			}

			return {
				protocol: a.protocol, //http:
				host: a.host, //www.myexample.com
				hostname: a.hostname, //www.myexample.com'
				port: a.port, //:80
				path: a.pathname, // /sub1/sub2
				query: a.search, // ?param1=val1&param2=val2
				queryParams: a.search.length > 1 ? app.util.getQueryStringParams(a.search.substr(1), notEncode) : {},
				hash: a.hash, // #OU812,5150
				url: a.protocol + '//' + a.host + a.pathname,
				urlWithQuery: a.protocol + '//' + a.host + a.port + a.pathname + a.search
			};
		},
		/**
		 * @function
		 * @description Appends a form-element with given arguments to a body-element and submits it
		 * @param {Object} args The arguments which will be attached to the form-element:<br/>
		 * <p>url</p>
		 * <p>fields - an Object containing the query-string parameters</p>
		 */
		postForm: function(args) {
			var form = $('<form>')
				.attr({ action: args.url, method: 'post' })
				.appendTo('body');
			var p;
			for (p in args.fields) {
				$('<input>')
					.attr({ name: p, value: args.fields[p] })
					.appendTo(form);
			}
			form.submit();
		},
		/**
		 * @function
		 * @description  Returns a JSON-Structure of a specific key-value pair from a given resource bundle
		 * @param {String} key The key in a given Resource bundle
		 * @param {String} bundleName The resource bundle name
		 * @param {Object} A callback function to be called
		 */
		getMessage: function(key, bundleName, callback) {
			if (!callback || !key || key.length === 0) {
				return;
			}
			var params = { key: key };
			if (bundleName && bundleName.length === 0) {
				params.bn = bundleName;
			}
			var url = app.util.appendParamsToUrl(app.urls.appResources, params);
			$.getJSON(url, callback);
		},
		/**
		 * @function
		 * @description Updates the states options to a given country
		 * @param {String} countrySelect The selected country
		 */
		updateStateOptions: function(countrySelect) {
			var country = $(countrySelect);
			if (!app.countries) {
				app.countries = app.page.pageData.countriesAndStates;
			}
			if (country.length === 0 || !app.countries[country.val()]) {
				return;
			}
			var form = country.closest('form');
			var stateField = country.data('stateField') ? country.data('stateField') : form.find("select[name$='_state']");
			if (stateField.length === 0) {
				return;
			}

			var form = country.closest('form'),
				c = app.countries[country.val()],
				arrHtml = [],
				labelSpan = form.find("label[for='" + stateField[0].id + "'] span").not('.required-indicator');

			// set the label text
			labelSpan.html(c.label);

			var s;
			for (s in c.regions) {
				arrHtml.push('<option value="' + s + '">' + c.regions[s] + '</option>');
			}
			// clone the empty option item and add to stateSelect
			var o1 = stateField
				.children()
				.first()
				.clone();
			stateField
				.html(arrHtml.join(''))
				.removeAttr('disabled')
				.children()
				.first()
				.before(o1);
			stateField[0].selectedIndex = 0;
		},
		/**
		 * @function
		 * @description Updates the number of the remaining character
		 * based on the character limit in a text area
		 */
		limitCharacters: function() {
			$('form')
				.find('textarea[data-character-limit]')
				.each(function() {
					var characterLimit = $(this).data('character-limit');
					var charsToRender = $(this).data('character-counter') ? 0 : characterLimit;
					var charCountHtml = String.format(
						app.resources.CHAR_LIMIT_MSG,
						'<span class="char-remain-count">' + charsToRender + '</span>',
						'<span class="char-allowed-count">' + characterLimit + '</span>'
					);
					var charCountContainer = $(this).next('div.char-count');
					if (charCountContainer.length === 0) {
						charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
					}
					charCountContainer.html(charCountHtml);
					// trigger the keydown event so that any existing character data is calculated
					$(this).change();
				});
		},
		/**
		 * @function
		 * @description Binds the onclick-event to a delete button on a given container,
		 * which opens a confirmation box with a given message
		 * @param {String} container The name of element to which the function will be bind
		 * @param {String} message The message the will be shown upon a click
		 */
		setDeleteConfirmation: function(container, message) {
			$(container).on('click', '.delete', function(e) {
				return confirm(message);
			});
		},
		/**
		 * @function
		 * @description Scrolls a browser window to a given x point
		 * @param {String} The x coordinate
		 */
		scrollBrowser: function(xLocation) {
			if ($(window).scrollTop() != xLocation) {
				$('html, body')
					.stop()
					.animate({ scrollTop: xLocation }, 500);
			}
		},

		/**
		 * @function
		 * @description render template
		 * @param {String} template
		 * @param {Object} data for template
		 */
		renderTemplate: function(template, data, skipXSSValidation) {
			return templateEngine.render(template, data, skipXSSValidation);
		},

		/**
		 * @function
		 * @description compares two objects
		 * @param {Object} Object 1
		 * @param {Object} Object 2
		 * @param {Array} Array of properties to exclude for compare
		 * @returns {Boolean}
		 */
		deepObjectCompare: function(obj1, obj2, paramsToExclude) {
			paramsToExclude = paramsToExclude || [];
			var isEqual = true;
			
			for (var prop in obj1) {
				if (paramsToExclude.indexOf(prop) === -1) {
					switch (typeof obj1[prop]) {
						case 'object':
							if (!app.util.deepObjectCompare(obj1[prop], obj2[prop], paramsToExclude)) {
								isEqual = false;
							}
							break;
						default:
							if (paramsToExclude.indexOf(prop) === -1 && obj2 && obj1[prop] !== obj2[prop]) {
								isEqual = false;
							}
					}
				}
				
				if (!isEqual) {
					break;
				}
			}
			
			return isEqual;
		},

		getDeepProperty: function(path, obj) {
			var pathArray = Array.isArray(path) ? path : path.split('.');

			for (var i = 0; i < pathArray.length; i++) {
				if (pathArray[i] in obj) {
					obj = obj[pathArray[i]];
				} else {
					return undefined;
				}
			}

			return obj;
		},

		/**
		* Returns app component by path as string
		* @param {string} path path to app component e.g 'app.component.test'
		* @returns {*}
		*/
		getAppComponentByPath: function(path) {
			var pathArray = path.split('.');
			var pathPart = pathArray.shift();
			var baseComponent = pathPart ? window[pathPart] : null;

			if (baseComponent && pathArray.length) {
				return this.getDeepProperty(pathArray, baseComponent);
			}

			return baseComponent;
		},

		throttle: function(callback, delay, scope) {
			clearTimeout(callback._tId);
			callback._tId = setTimeout(function() {
				callback.call(scope);
			}, delay || 100);
		},
		fixElement: function(selector, options) {
			app.components.global.stickykit.stick(selector, options);
		},
		/**
		 * @function
		 * @description Detect, is the "sessionStorage" is not blocked.
		 */
		hasStorage: function() {
			var uid = new Date();
			try {
				sessionStorage.setItem(uid, uid);
				sessionStorage.removeItem(uid);
				return true;
			} catch (e) {
				return false;
			}
		},
		/**
		 * @function
		 * @description Detects if element full height on the viewport
		 * @param {object} DOM object
		 */
		isVisibleFullHeight: function(el) {
			return el.getBoundingClientRect().top >= 0 && el.getBoundingClientRect().bottom <= window.innerHeight;
		},

		/**
		 * @function
		 * @description Returns event of css transition ending
		 */
		getTransEndEvent: function() {
			var t,
				el = document.createElement('fakeelement'),
				transitions = {
					transition: 'transitionend',
					OTransition: 'oTransitionEnd',
					MozTransition: 'transitionend',
					WebkitTransition: 'webkitTransitionEnd'
				};

			for (t in transitions) {
				if (el.style[t] !== undefined) {
					return transitions[t];
				}
			}
		},

		/**
		 * @function
		 * @description  checks if the given uri is urlencoded
		 * @param {String} uri to check
		 */
		isEncodedUri: function(uri) {
			return decodeURIComponent(uri) !== uri;
		},

		/**
		 * @function
		 * @description  Gets param value from given url
		 * @param {String} url to check
		 * @param {String} param to extract
		 */
		getParamFromUrl: function(url, param) {
			var results = new RegExp('[?&]' + param + '=([^&#]*)').exec(url);

			return results != null ? results[1] : 0;
		},
		
		/**
		 * @function
		 * @description Returns configuration by given path
		 * @param {String} path to configuration separated by dot
		 * @param {String} default value to return
		 */		
		getConfig: function(path, defaults){
			var config = app.util.getDeepProperty(path, app.configs);
			return typeof config === 'undefined' ? defaults : config;
		},
		
		/**
		 * @function
		 * @description Add class to list elements on new line
		 * @param {DOMElement} An ul element with list elements
		 */	
		determineListLineBreak: function(ul){
			var children = [].slice.call(ul.children);
			children.forEach(function(item, i, arr){
				if(i > 0){
					if(item.offsetTop > arr[i - 1].offsetTop){
						arr[i - 1].classList.add('last-on-line');
					}
					else{
						arr[i-1].classList.remove('last-on-line');
					}
				}
			});
		},
		
		removeEmojis: function(str) {
			return str.replace(emojisRegex, "");
		},

		isElementPartiallyVisible: function(element, percentage) {
			var variation = (element.outerHeight() * (percentage || 30)) / 100;
			variation = variation < 200 ? variation : 200;
			var visibleTop = window.innerHeight + $(window).scrollTop() > element.offset().top + variation;
			var visibleBottom = $(window).scrollTop() <= element.offset().top + element.outerHeight();
			return visibleTop && visibleBottom;
		},

		scrollToElement: function(element, params) {
			params = params || {};
			var top = element.getBoundingClientRect().top + window.pageYOffset - $('header').outerHeight();
			var scrollConfig = $.extend({}, { top: top }, params);

			if (app.device.isTabletUserAgent() && (app.device.isIOS() || app.device.isMacOS())) {
				this.scrollBrowser(top);
			} else {
				window.scrollTo(scrollConfig);
			}
		},
		removeDISParameters: function(url) {
			var parameters = ['sw', 'sh', 'sm'];

			return app.util.removeParamsFromURL(url, parameters);
		},
		/**
		 * Generates unique query selector for HTML element
		 * @param {HTMLElement} htmlElement Element to generate query selector for
		 * @returns {String}
		 */
		generateUniqueQuerySelector: function(htmlElement) {
			if (htmlElement.tagName.toLowerCase() === 'html') {
				return 'HTML';
			}

			var str = htmlElement.tagName + (htmlElement.id !== '' ? '#' + htmlElement.id : '');

			if (htmlElement.className) {
				var classes = htmlElement.className.split(/\s/);

				for (var i = 0, len = classes.length; i < len; i++) {
					if (classes[i]) {
						str += '.' + classes[i];
					}
				}
			}

			return app.util.generateUniqueQuerySelector(htmlElement.parentNode) + ' > ' + str;
		},

		isApplePayAllowedForHost: function(host) {
			return app.configs.applePay === undefined || app.configs.applePay.unsupportedHosts.indexOf(host) === -1;
		},

		isApplePaySupported: function() {
			return app.util.isSafari(navigator?.userAgent) && this.isApplePayAllowedForHost(document.location.host)
				&& window.ApplePaySession && window.ApplePaySession.canMakePayments();
		},

		/**
		 * Checks if the userAgent string corresponds to Safari browser on iOS or macOS.
		 * @param {string} userAgent - The userAgent string to check.
		 * @returns {boolean} - true if the userAgent corresponds to Safari on iOS or macOS, false otherwise.
		 */
		isSafari(userAgent) {
			// eslint-disable-next-line max-len
			const SAFARI_REGEX = /^(?=.*\b(?:Macintosh|iP(?:hone|ad|od)).*Safari\b)(?!.*(?:CriOS|FxiOS|OPiOS|EdgiOS|Chrome|Opera)).*$/;

			return SAFARI_REGEX.test(userAgent);
		},

		/**
		 * Returns true if GooglePay is supported
		 * @returns {boolean}
		 */
		isGooglePaySupported() {
			return typeof window !== 'undefined'
				&& document.location.protocol === 'https:'
				&& !app.util.isSafari(navigator?.userAgent);
		},

		/**
		 * Return number with sign
		 * @param {number|string} value - Value to be formatted
		 * @param {number|string} [formatByValue=value] - Value based on which will be taken sign
		 * @return {string}
		 */
		getNumberWithSign: function(value, formatByValue = value) {
			const signs = { 1: '+', 0: '', '-1': '-' };

			return signs[Math.sign(formatByValue)] + Math.abs(value);
		},

		fetchForm: function(form) {
			const method = form.method.toUpperCase();
			const formData = new FormData(form);
			const params = {
				format: 'ajax'
			};

			const options = {
				method: method
			};

			if (method === 'POST') {
				options.body = formData;
			} else if (method === 'GET') {
				for (const [name, value] of formData) {
					params[name] = value;
				}
			}

			return fetch(this.appendParamsToUrl(form.action, params), options);
		},

		checkUserExist: function(selector, selectorName, callbackProp) {
			var customerEmail = $(selector).find('input').first().val();
			var params = [{
				name: selectorName,
				value: customerEmail
			}];

			app.ajax.getJson({
				type: 'POST',
				url: app.urls.CheckUserLogin,
				data: params,
				callback: function(resp) {
					callbackProp(resp);
				}
			});
		},

		requestAnimationFrame: function() {
			return window.requestAnimationFrame
				|| window.webkitRequestAnimationFrame
				|| window.mozRequestAnimationFrame
				|| window.oRequestAnimationFrame
				|| window.msRequestAnimationFrame
				|| (function(callback) {
					setTimeout(callback, 1000 / 60);
				});
		},

		/**
		 * Debounces a function, ensuring it is not called more frequently than a specified timeout.
		 * @param {Function} func - The function to be debounced.
		 * @param {number} [timeout=300] - The timeout duration in milliseconds.
		 * @returns {Function} - The debounced function.
		 */
		debounce(func, timeout = 300) {
			let timer;

			return (...args) => {
				clearTimeout(timer);

				timer = setTimeout(() => { func.apply(this, args); }, timeout);
			};
		},
		/**
		 * Builds a list of threshold values based on the specified start point and number of steps.
		 *
		 * @param {number} startPoint - The starting point for building the threshold list.
		 * @param {number} numSteps - The number of steps to generate thresholds.
		 * @returns {number[]} An array of threshold values.
		 * @throws Will throw an error if `numSteps` is not a positive integer.
		 *
		 * @example
		 * // Generate threshold list starting from 0 with 5 steps:
		 * const thresholds = buildThresholdList(0, 5);
		 * // Result: [0, 0.2, 0.4, 0.6, 0.8, 1]
		 */
		buildThresholdList(startPoint, numSteps) {
			const thresholds = [];

			if (!Number.isInteger(numSteps) || numSteps <= 0) {
				throw new Error('numSteps must be a positive integer.');
			}

			for (let i = startPoint; i <= numSteps; i++) {
				const ratio = i / numSteps;

				thresholds.push(ratio);
			}

			thresholds.push(0);

			return thresholds;
		},

		promiseWithRetry: function(promiseHandle, retries = 3, loggerData) {
			return new Promise((resolve, reject) => {
				const attemptPromise = (attempt) => {
					promiseHandle()
						.then(response => {
							resolve(response);
						})
						.catch(error => {
							if (attempt < retries) {
								if (loggerData?.warningBody) {
									this.systemLog(loggerData.csrf_token, loggerData.warningBody);
								}

								attemptPromise(attempt + 1);
							} else {
								if (loggerData?.errorBody) {
									this.systemLog(loggerData.csrf_token, loggerData.errorBody);
								}

								reject(error);
							}
						});
				};

				attemptPromise(1);
			});
		},

		systemLog: function(csrf_token, body) {
			return fetch(this.appendParamToURL(app.urls.systemLog, 'csrf_token', csrf_token), {
				method: 'POST',
				body: JSON.stringify(body)
			});
		}
	};
})((window.app = window.app || {}), jQuery);
